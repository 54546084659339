import { useGetUnitForListingStatusQuery } from "gql/generated/graphql";
export const useDisplayPromotedListing = (unitId) => {
    const { data, loading: unitLoading } = useGetUnitForListingStatusQuery({
        variables: { unitId },
    });
    if (unitLoading || !data) {
        return {
            loading: unitLoading,
            shouldDisplayPromotedListing: false,
            isPubliclyListed: false,
            isAlreadyPromotedForRealtor: false,
            isApproved: false,
            listingStatus: null,
        };
    }
    const { unit: { publicly_listed: isPubliclyListed, active_premium_syndications: partners, listing_status: listingStatus, }, } = data;
    // update this to check for other partners when more partners are added
    const isAlreadyPromotedForRealtor = partners.some(({ partner_name }) => partner_name === "realtor_premier");
    const isApproved = (listingStatus === null || listingStatus === void 0 ? void 0 : listingStatus.status) === "approved";
    const shouldDisplayPromotedListing = isPubliclyListed && isApproved && !isAlreadyPromotedForRealtor;
    return {
        loading: unitLoading,
        shouldDisplayPromotedListing,
        isPubliclyListed,
        isAlreadyPromotedForRealtor,
        isApproved,
        listingStatus,
    };
};
