import React from "react";
import { Flex, Box } from "@rent_avail/core";
import { Zap } from "@rent_avail/icons";
import Tag from "@rent_avail/tag";
import { Heading } from "@rent_avail/typography";
import { PURPLE_700 } from "./purchase/constants";
const PromoteListingBannerTitle = () => (React.createElement(Flex, { sx: { gap: 1 } },
    React.createElement(Box, { as: Zap, size: 24, sx: { color: PURPLE_700, fill: "currentColor" } }),
    React.createElement(Heading, { as: "h4", id: "promoted-listings-title-id", color: { PURPLE_700 }, fontSize: "1.5rem", fontWeight: "800" }, "Introducing Promoted Listings"),
    React.createElement(Tag, { alignSelf: "flex-start", bg: "blue_500", color: "white" }, "Beta")));
export default PromoteListingBannerTitle;
