import { gql } from "@apollo/client"

const LEASE_CHARGES_FRAGMENT = gql`
  fragment LeaseCharges on Lease {
    id
    animal_rules
    date_rent_due
    dog_charge
    late_fee
    move_in_fee
    move_out_fee
    parking
    pet_deposit
    prorated
    prorated_rent
    security_deposit_bank_address1
    security_deposit_bank_city
    security_deposit_bank_name
    security_deposit_bank_state
    security_deposit_bank_zip
    security_deposit_data
  }
`

export default LEASE_CHARGES_FRAGMENT
