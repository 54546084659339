import { Box, Flex } from "@rent_avail/core";
import React, { useState } from "react";
import { Button, DulceField, AlignRow, AlignRow__Item } from "ui_components";
const AddUnit = ({ building, submitting, showBack, handleBack, handleNext, errorMessage, }) => {
    const [unitNumber, setUnitNumber] = useState("");
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { as: "h4" }, "Add a unit"),
        React.createElement(Box, { as: "p" }, `${building.address1}, ${building.city}, ${building.state}, ${building.zip}`),
        React.createElement(DulceField, { validators: {
                presence: [{ message: "Enter your unit number" }],
            }, labelText: "Enter the Unit Number", onChange: ({ target }) => {
                setUnitNumber(target.value);
            } }),
        errorMessage ? (React.createElement(Flex, { sx: { alignItems: "flex-end", flexDirection: "column" } },
            React.createElement(Box, { sx: { color: "red_500", fontSize: "1.2rem" }, key: errorMessage }, errorMessage))) : null,
        React.createElement(AlignRow, { className: "margin-2-t", grow: true, style: { borderCollapse: "collapse" } },
            showBack && (React.createElement(AlignRow__Item, { middle: true, className: "padding-0p5-r pull-left" },
                React.createElement(Button, { disabled: submitting, onClick: () => handleBack() }, "Back"))),
            React.createElement(AlignRow__Item, { middle: true, className: "pull-right" },
                React.createElement(Button, { className: "button--primary", loading: submitting, disabled: !unitNumber || submitting, onClick: () => {
                        handleNext({ unitNumber });
                    } }, submitting ? "Saving" : "Add Unit")))));
};
export default AddUnit;
