import React, { useState } from "react";
import Snackbar from "Snackbar";
import confirmNewUnit from "ui_components/Units/confirmNewUnit";
import { useHistory } from "react-router";
import { Box } from "@rent_avail/core";
import { GetMeDocument, useCreateBuildingMutation, useCreateUnitMutation, } from "gql/generated/graphql";
import { parseErrorMessages } from "utils";
import SelectBuilding from "./components/SelectBuilding";
import AddUnit from "./components/AddUnit";
import AddBuilding from "./components/AddBuilding";
const AddProperty = ({ building: initialBuilding, currentUser, nextUnitPrice, onSuccess }, ref) => {
    const [selectedBuilding, setSelectedBuilding] = useState(initialBuilding);
    const [submitting, setSubmitting] = useState(false);
    const [showBuildingEditFrom, setShowBuildingEditForm] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [createBuildingMutation] = useCreateBuildingMutation();
    const [createUnitMutation] = useCreateUnitMutation();
    const { push } = useHistory();
    const parseAndSetErrors = (errors) => {
        const errorArray = Object.values(errors).flat();
        // removing duplicate errors
        const uniqueErrors = { base: [...new Set(errorArray)] };
        const parsedErrors = parseErrorMessages(uniqueErrors, false);
        setErrorMessage(parsedErrors);
    };
    const createBuilding = async ({ building }) => {
        setSubmitting(true);
        try {
            const { data: { create_building: result }, } = await createBuildingMutation({
                variables: building,
            });
            if (result.__typename === "Error") {
                parseAndSetErrors(result.errors);
                throw new Error("Failed to create building!");
            }
            setErrorMessage(null);
            return result.building;
        }
        catch {
            setSubmitting(false);
            return null;
        }
    };
    const createUnit = async ({ building, unitNumber, unitType, }) => {
        setSubmitting(true);
        try {
            const { data: { create_unit: result }, } = await createUnitMutation({
                variables: {
                    buildingId: building.id,
                    unitNumber,
                    unitType,
                },
                refetchQueries: [{ query: GetMeDocument }],
            });
            if (result.__typename === "Error") {
                parseAndSetErrors(result.errors);
                throw new Error("Failed to create unit!");
            }
            setErrorMessage(null);
            return result.unit;
        }
        catch {
            setSubmitting(false);
            return null;
        }
    };
    const confirmUnit = async ({ buildingAddress, unitNumber, }) => {
        if (parseInt(nextUnitPrice) > 0) {
            try {
                await confirmNewUnit({
                    currentUser,
                    nextUnitPrice,
                    unitNumber: unitNumber || null,
                    buildingAddress,
                });
            }
            catch {
                return false;
            }
        }
        return true;
    };
    const afterCreate = ({ unit }) => {
        const { feature_paths: { manage }, } = unit;
        push(manage);
        onSuccess();
    };
    const handleBuildingSave = async ({ building, unitNumber, unitType, }) => {
        if (await confirmUnit({ buildingAddress: building.address1, unitNumber })) {
            const newBuilding = await createBuilding({ building });
            const unit = await createUnit({ building: newBuilding, unitNumber, unitType });
            if (unit) {
                afterCreate({ unit: unit });
                Snackbar({ kind: "success", message: "Building Created!" });
            }
        }
    };
    const handleUnitSave = async ({ building, unitNumber, }) => {
        if (await confirmUnit({ buildingAddress: building.address1, unitNumber })) {
            const unit = await createUnit({ building, unitNumber });
            if (unit) {
                afterCreate({ unit: unit });
                Snackbar({ kind: "success", message: "Unit Created!" });
            }
        }
    };
    return (React.createElement(Box, { as: "div", sx: { pt: "1rem " } },
        !selectedBuilding && !showBuildingEditFrom && (React.createElement(SelectBuilding, { ref: ref, handleNext: ({ building }) => {
                setSelectedBuilding(building);
                setShowBuildingEditForm(!building);
            } })),
        !selectedBuilding && showBuildingEditFrom && (React.createElement(AddBuilding, { submitting: submitting, handleBack: () => {
                setSelectedBuilding(undefined);
                setShowBuildingEditForm(false);
            }, handleNext: ({ building, unitNumber, unitType, }) => {
                if (building.address1) {
                    handleBuildingSave({ building, unitNumber, unitType });
                }
            }, errorMessage: errorMessage })),
        selectedBuilding && (React.createElement(AddUnit, { showBack: !initialBuilding, building: selectedBuilding, submitting: submitting, handleBack: () => setSelectedBuilding(undefined), handleNext: ({ unitNumber }) => {
                if (unitNumber) {
                    handleUnitSave({ building: selectedBuilding, unitNumber });
                }
            }, errorMessage: errorMessage }))));
};
export default React.forwardRef(AddProperty);
