import React from "react";
import { Button } from "@rent_avail/controls";
import { useFormState } from "react-final-form";
import { isSubscribed, PromotedListingPartners } from "./common";
import useCurrentListing from "../../context/useCurrentListing";
const PromotedListingPurchaseButton = ({ loading }) => {
    const { currentUnit } = useCurrentListing();
    const { dirty, valid, values } = useFormState();
    const enabled = dirty &&
        [
            PromotedListingPartners.RENTDOT_PREMIER,
            PromotedListingPartners.ZUMPER_PREMIER,
            PromotedListingPartners.REALTOR_PREMIER,
        ]
            .filter((partner) => !isSubscribed(partner, currentUnit))
            .some((partner) => values[partner]);
    return (React.createElement(Button, { mt: 1, disabled: loading || !valid || !enabled, width: 1, variant: "primary", textAlign: "center" }, "Purchase"));
};
export default PromotedListingPurchaseButton;
