import flute, { Model } from "redux-flute"

function LANDLORD(message) { return message.from == "Landlord" }
function LEAD(message) { return message.from == "Lead" }
function READ(message) { return message.read }
function UNREAD(message) { return !message.read }

class Lead extends Model {
  static routes = {
    INDEX: "/api/v2/landlords/units/:unit_id/leads",
    GET: "/api/v2/landlords/units/:unit_id/leads/:id",
    POST: "/api/v2/landlords/units/:unit_id/leads",
    PUT: "/api/v2/landlords/units/:unit_id/leads/:id",
    DELETE: "/api/v2/landlords/units/:unit_id/leads/:id",
  }

  static schema = {
    unit_id: Number,
    name: String,
    email: String,
    phone: String,
    source: String,
    archived: Boolean,
    message: String,
    note: String,
    message_sent: Boolean,
    applicant_status: String,
    type_of_lead: String,
    manual: Boolean,
    // Associations
    lead_messages: Array,
    lead_messages_attributes: Array,
    messages_sent: Number,
    responded: Boolean,
    showings: Object,
    unread_messages: Boolean,
    tour_requests: Array,
    _timestamps: true,
  }

  markAllAsRead() {
    const lead_messages_attributes = this.lead_messages
      .filter(UNREAD)
      .filter(LEAD)
      .map(({ id }) => ({ id, read: true }))
    if (lead_messages_attributes.length || this.unread_messages) { return this.updateAttributes({ lead_messages_attributes, unread_messages: false }) }
    return Promise.resolve(this)
  }

  get messagesCount() {
    return this.lead_messages.length + 1
  }

  static manualSourceOptions = [
    "Craigslist",
    "Personal Contact",
    "Newspaper Ad",
    "Referral",
    "Open House",
    "Nextdoor",
    "Other",
  ]

  get messageCountSummary() {
    return `${"Message".pluralize(this.messagesCount)} (${this.messagesCount})`
  }

  get unreadMessagesCount() {
    if (this.lead_messages.length == 0 && this.unread_messages) { return 1 }
    return this.lead_messages.filter(UNREAD).filter(LEAD).length
  }

  get unreadMessagesSummary() {
    return `${this.unreadMessagesCount} unread ${"message".pluralize(this.unreadMessagesCount)}`
  }

  get hasTourRequested() {
    return !!this.tour_requests.length
  }
}

export default flute.model(Lead)
