import React from "react";
import { Tooltip, TooltipContent, TooltipTarget } from "@rent_avail/tooltip";
import { Box } from "@rent_avail/core";
import { Zap } from "@rent_avail/icons";
import { Text } from "@rent_avail/typography";
import { PURPLE_700 } from "./promoted/purchase/constants";
const PromotedListingTooltip = () => (React.createElement(Tooltip, null,
    React.createElement(TooltipTarget, { "data-cy": "zap-tooltip", sx: {
            display: "inline-flex",
            alignItems: "center",
            marginRight: "0.25rem",
        } },
        React.createElement(Box, null,
            React.createElement(Box, { as: Zap, sx: {
                    color: PURPLE_700,
                    fill: "currentColor",
                } }))),
    React.createElement(TooltipContent, { style: {
            backgroundColor: PURPLE_700,
            padding: "1rem",
            margin: "1rem",
            borderRadius: "0.375rem",
        } },
        React.createElement(Text, { style: { fontWeight: "bold" } }, "Promoted Listing Lead"),
        React.createElement(Text, null, "This lead came from your promoted listing."))));
export default PromotedListingTooltip;
