import React from "react";
import dayjs from "dayjs";
import { Box, Card, Flex, Stack } from "@rent_avail/core";
import { Text } from "@rent_avail/typography";
import { useParams } from "react-router-dom";
import { Zap } from "react-feather";
import { useGetUnitForListingStatusQuery } from "gql/generated/graphql";
import { PURPLE_100, PURPLE_700 } from "./purchase/constants";
function getPromotedInfo(provider, expiresOn, isPubliclyListed) {
    const formattedExpiresOn = dayjs(expiresOn).format("MMM DD");
    const mapToProviderName = {
        rentdot_premier: "Rent. network",
        zumper_premier: "Zumper network",
        realtor_premier: "Realtor.com",
    };
    if (isPubliclyListed) {
        return (React.createElement(React.Fragment, null,
            React.createElement("strong", null, "Your listing is promoted"),
            " on ",
            mapToProviderName[provider],
            " until",
            " ",
            formattedExpiresOn));
    }
    return (React.createElement(React.Fragment, null,
        "Your listing is off-market.",
        " ",
        React.createElement("strong", null,
            "Publish your listing to continue promotion ",
            mapToProviderName[provider],
            " until",
            " ",
            formattedExpiresOn)));
}
export const PromotedListingCard = () => {
    const { id: unitId } = useParams();
    const { data, loading } = useGetUnitForListingStatusQuery({ variables: { unitId } });
    if (loading)
        return null;
    if (!data)
        return null;
    const { unit: { publicly_listed: isPubliclyListed, active_premium_syndications: partners }, } = data;
    if (partners.length === 0)
        return null;
    return (React.createElement(Card, { sx: {
            bg: PURPLE_100,
            borderWidth: 0,
            boxShadow: "none!important",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            ml: [-1, -1, 0, 0],
            mr: [-1, -1, 0, 0],
            mb: -2,
            py: 1,
            px: 2,
        } }, partners.map(({ id, partner_name: name, end_date: endDate }) => (React.createElement(Flex, { key: id, sx: { gap: "0.5rem" } },
        React.createElement(Box, { as: Zap, size: 24, sx: { color: PURPLE_700, fill: "currentColor", minWidth: "2rem" } }),
        React.createElement(Stack, { sx: { gap: "0.25rem" } },
            React.createElement(Text, { fontSize: "1.334rem", color: PURPLE_700 }, getPromotedInfo(name, endDate, isPubliclyListed))))))));
};
