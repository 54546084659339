import React, { Component } from "react"
import PropTypes from "prop-types"
import { Card, AlignRow, AlignRow__Item, FadeCollapseList } from "ui_components"
import { Eye, Archive } from "react-feather"
import flute from "redux-flute"
import { unitType } from "types"
import { s3AssetUrl } from "utils"

// Listing partner site logos
import apartableSvg from "assets/listing-logos/apartable.svg"
import apartmentscomSvg from "assets/listing-logos/apartmentscom.svg"
import apartmentlistSvg from "assets/listing-logos/apartmentlist.svg"
import dwellsyPng from "assets/listing-logos/dwellsy.png"
import lovelySvg from "assets/listing-logos/lovely.svg"
import padmapperSvg from "assets/listing-logos/padmapper.svg"
import realtorSvg from "assets/listing-logos/realtor.svg"
import walkscoreSvg from "assets/listing-logos/walkscore.svg"
import zumperSvg from "assets/listing-logos/zumper.svg"
import RequestApplicationConsent from "resources/confirmable/RequestApplicationConsent"
import ReactiveRecord from "reactiverecord"
import { Dialog, FullscreenDialog, DialogHeader } from "@rent_avail/dialog"
import { Container } from "@rent_avail/layout"
import { Box, Flex } from "@rent_avail/core"
import { Text } from "@rent_avail/typography"
import { EditLeadButton } from "./leads"
import * as styles from "./ListingsStyles.scss"
import LeadMessageActions from "./LeadMessageActions"
import LeadRequestingTenantApplicationsModal from "./LeadRequestingTenantApplicationsModal"
import LeadHeader from "./LeadHeader"
import { RENTBERRY, LEAD_TYPES } from "./config"
import PromotedListingTooltip from "./PromotedListingTooltip"

const listingPartners = {
  Rentable: s3AssetUrl("Logos/rentable.png"),
  Apartable: apartableSvg,
  "Apartment List": apartmentlistSvg,
  "apartmentpicks.com": s3AssetUrl("Logos/ap-logo.svg"),
  "Apartments.com": apartmentscomSvg,
  Avail: s3AssetUrl("marketing/logo.svg"),
  Dwellsy: dwellsyPng,
  "www.doorsteps.com": s3AssetUrl("marketing/doorsteps.svg"),
  Lovely: lovelySvg,
  Padmapper: padmapperSvg,
  Trulia: s3AssetUrl("marketing/trulia.svg"),
  "www.realtor.com": realtorSvg,
  Walkscore: walkscoreSvg,
  Zumper: zumperSvg,
  Zillow: s3AssetUrl("marketing/zillow.svg"),
  "Rent.": s3AssetUrl("Logos/rent-logo.png"),
  [RENTBERRY]: s3AssetUrl("Logos/Rentberry_Logo_Icon.svg"),
  Hotpads: s3AssetUrl("Logos/Hotpads.svg"),
}

const User = ReactiveRecord.model("User")

const Application = flute.model("Application")

function RELOAD() {
  return this.constructor.find(this.id, { unit_id: this.unit_id, include: "tour_requests" })
}

const propTypes = {
  UNIT: unitType.isRequired,
  LEAD: PropTypes.shape({
    id: PropTypes.number,
    unit_id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    updateAttributes: PropTypes.func,
    markAllAsRead: PropTypes.func,
    archived: PropTypes.bool,
    manual: PropTypes.bool,
    showings: PropTypes.arrayOf(PropTypes.shape({})),
    createdAt: PropTypes.instanceOf(Date),
    source: PropTypes.string,
    message: PropTypes.string,
    type_of_lead: PropTypes.string,
  }).isRequired,
  showPromotedListingTooltip: PropTypes.bool.isRequired,
}

class Lead extends Component {
  constructor(props) {
    super(props)
    const { LEAD } = props
    this.state = {
      showMessages: false,
      creatingApplication: false,
      showModal: false,
      errors: null,
      showConsent: false,
      user: null,
    }

    this.toggleModal = () => {
      const { showModal } = this.state
      this.setState({ showModal: !showModal })
    }

    this.toggleShowMessages = () => {
      const { showMessages } = this.state
      this.setState({ showMessages: !showMessages })
    }

    this.RELOAD_LEAD = () => RELOAD.call(LEAD)

    this.CREATE_APPLICATION = () => {
      const { UNIT } = this.props
      this.setState({ creatingApplication: true })
      return Application.create({
        move_in: Date.create().format("%F"),
        start_date: Date.create().format("%F"),
        end_date: Date.create("next year").format("%F"),
        unit_id: LEAD.unit_id,
        required_residence_history_years: UNIT.required_residence_history_years,
        required_employment_history_years: UNIT.required_employment_history_years,
        require_income_verification: UNIT.require_income_verification,
        require_credit_report: UNIT.require_credit_report && UNIT.require_transunion,
        require_background_report: UNIT.require_background_report && UNIT.require_transunion,
        require_evictions_report: UNIT.require_eviction_report && UNIT.require_transunion,
        send_reference_request_emails: UNIT.send_reference_request_emails,
        require_answer_to_bankruptcy: UNIT.require_answer_to_bankruptcy,
        require_answer_to_eviction: UNIT.require_answer_to_eviction,
        require_answer_to_felony: UNIT.require_answer_to_felony,
        require_answer_to_refused_rent: UNIT.require_answer_to_refused_rent,
        require_answer_to_smoking: UNIT.require_answer_to_smoking,
        request_type: "manual",
        applicants_attributes: [
          {
            full_name: LEAD.name,
            email: LEAD.email,
            lead_id: LEAD.id,
            application_creator: false,
          },
        ],
      })
        .then(() => {
          this.RELOAD_LEAD()
          this.setState({ creatingApplication: false })
        })
        .catch(({ errors }) => {
          this.setState({
            creatingApplication: false,
            errors: errors["applicants[0].email"][0],
          })
        })
    }

    this.handleApplicationSubmit = async () => {
      const user = await User.all()
      const { UNIT } = this.props
      const { require_transunion } = UNIT

      if (
        (!user.tu_consent && require_transunion) ||
        (user.tu_consent && require_transunion && !user.address1)
      ) {
        this.setState({ user })
        this.setState({ showConsent: true })
      } else {
        this.CREATE_APPLICATION()
      }
    }

    this.TOGGLE_ARCHIVED = () => LEAD.updateAttributes({ archived: !LEAD.archived })
  }

  componentDidUpdate(_prevProps, prevState) {
    const { showMessages } = this.state
    const { LEAD } = this.props
    if (showMessages && !prevState.showMessages && !this.messagesSeenOnce) {
      this.messagesSeenOnce = true
      LEAD.markAllAsRead()
    }
  }

  render() {
    const { errors, creatingApplication, showMessages, showModal, showConsent, user } = this.state
    const { LEAD, UNIT, showPromotedListingTooltip } = this.props
    const renderPromotedListingTooltip =
      showPromotedListingTooltip && LEAD.type_of_lead === LEAD_TYPES.PAID

    const leadSource = `Source: ${LEAD.source}`
    return (
      <div className="margin-1-t" role="listitem" aria-label={LEAD.name}>
        <Card archived={LEAD.archived}>
          <AlignRow grow>
            <AlignRow__Item grow top className={styles.leadNameContainer}>
              <LeadHeader LEAD={LEAD} />
            </AlignRow__Item>
            {/* Desktop */}
            <AlignRow__Item top className="nowrap padding-0p5-l right hidden-sm-below">
              <div className="center-row">
                <EditLeadButton unitID={UNIT.id} leadID={LEAD.id} />
                {LEAD.archived ? (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={this.TOGGLE_ARCHIVED}
                    onKeyPress={this.TOGGLE_ARCHIVED}
                    className="margin-0p5-l center-row pointer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Eye
                      height="1em"
                      width="1em"
                      style={{ verticalAlign: "top" }}
                      title="Unarchive"
                    />
                    &nbsp;Unarchive
                  </div>
                ) : (
                  <div
                    role="button"
                    tabIndex={0}
                    onClick={this.TOGGLE_ARCHIVED}
                    onKeyPress={this.TOGGLE_ARCHIVED}
                    className="margin-0p5-l center-row pointer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Archive
                      height="1em"
                      width="1em"
                      style={{ verticalAlign: "top" }}
                      title="Archive"
                    />
                    &nbsp;Archive
                  </div>
                )}
              </div>
              {LEAD.source ? (
                <Flex sx={{ justifyContent: "flex-end" }}>
                  {renderPromotedListingTooltip && <PromotedListingTooltip />}
                  {!LEAD.manual ? (
                    <Box
                      as="img"
                      title={leadSource}
                      src={listingPartners[LEAD.source]}
                      width="70"
                      className="hidden-sm-below"
                      style={{ minWidth: "6.25rem" }}
                      alt={leadSource}
                    />
                  ) : (
                    <Text sx={{ mt: "0.5rem" }}>{LEAD.source}</Text>
                  )}
                </Flex>
              ) : null}
            </AlignRow__Item>
          </AlignRow>
          {/* Mobile */}
          <AlignRow grow className="visible-sm-below margin-0p5-t">
            <AlignRow__Item top className="padding-0p5-l">
              <Box sx={{ pl: "3.5rem" }}>
                {LEAD.source && !LEAD.manual ? (
                  <Flex>
                    {renderPromotedListingTooltip && <PromotedListingTooltip />}
                    <Box
                      as="img"
                      title={`This response came from your listing posted on ${LEAD.source}`}
                      src={listingPartners[LEAD.source]}
                      width="70"
                      className="display-block"
                      style={{ minWidth: "9rem" }}
                      alt={`This response came from your listing posted on ${LEAD.source}`}
                    />
                  </Flex>
                ) : null}
                <Flex sx={{ justifyContent: "flex-end", mt: "2rem" }}>
                  <Box>
                    <EditLeadButton unitID={UNIT.id} leadID={LEAD.id} />
                  </Box>
                  {LEAD.archived ? (
                    <Flex>
                      <Box
                        as="span"
                        role="button"
                        tabIndex={0}
                        onClick={this.TOGGLE_ARCHIVED}
                        onKeyPress={this.TOGGLE_ARCHIVED}
                        className="center-children"
                        sx={{ pl: "0.5rem" }}
                      >
                        <Eye
                          height="1em"
                          width="1em"
                          className="pointer"
                          style={{ verticalAlign: "top" }}
                          title="Unarchive"
                        />
                      </Box>
                      &nbsp;Unarchive
                    </Flex>
                  ) : (
                    <Flex>
                      <Box
                        as="span"
                        role="button"
                        tabIndex={0}
                        onClick={this.TOGGLE_ARCHIVED}
                        onKeyPress={this.TOGGLE_ARCHIVED}
                        className="center-children"
                        sx={{ pl: "1rem" }}
                      >
                        <Archive
                          height="1em"
                          width="1em"
                          className="pointer"
                          style={{ verticalAlign: "top" }}
                          title="Archive"
                        />
                      </Box>
                      &nbsp;Archive
                    </Flex>
                  )}
                </Flex>
              </Box>
            </AlignRow__Item>
          </AlignRow>
          <Dialog open={showConsent} toggle={() => this.setState({ showConsent: false })}>
            <FullscreenDialog py="0" style={{ overflowY: "auto", zIndex: 50 }}>
              <Container>
                <DialogHeader title="" />
              </Container>
              <RequestApplicationConsent
                user={user}
                setToggle={() => this.setState({ showConsent: false })}
                proceedFunction={this.CREATE_APPLICATION}
                ctaText="Send Application Request"
                loadingText="Saving"
              >
                <h5 data-cy="request-application-consent-h5">Home or Office Address</h5>
                <p>
                  To request an application you must have <strong>your current address</strong> on
                  file. Please confirm your address. Note: changing your address here will update
                  your Account Settings.
                </p>
              </RequestApplicationConsent>
            </FullscreenDialog>
          </Dialog>
          <FadeCollapseList transitionAppear={false} className="margin-1-t">
            <LeadMessageActions
              creatingApplication={creatingApplication}
              createApplication={this.handleApplicationSubmit}
              errors={errors}
              LEAD={LEAD}
              toggleModal={this.toggleModal}
              toggleShowMessages={this.toggleShowMessages}
              showMessages={showMessages}
              UNIT={UNIT}
            />
          </FadeCollapseList>
        </Card>
        <LeadRequestingTenantApplicationsModal show={showModal} toggleModal={this.toggleModal} />
      </div>
    )
  }
}

Lead.propTypes = propTypes

export default Lead
