import React, { useEffect, useState } from "react";
import { Dialog, DialogTarget, ConfirmationDialog, DialogHeader } from "@rent_avail/dialog";
import { Button } from "@rent_avail/controls";
import { Text } from "@rent_avail/typography";
import { NEXTDOOR_CLIENT_ID, NEXTDOOR_CALLBACK_URL } from "imported-vars";
import LoadingSpinnerCircle from "ui_components/LoadingSpinnerCircle";
const PublishToNextdoorConfirmModal = ({ open, toggle, unitId, }) => {
    const [loadingSpinner, setLoadingSpinner] = useState(false);
    const nextdoorShareUrlWithCallback = `https://www.nextdoor.com/v3/authorize/?scope=openid%20publish_api&client_id=${NEXTDOOR_CLIENT_ID}&redirect_uri=${NEXTDOOR_CALLBACK_URL}&state=${JSON.stringify({ unitId })}`;
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" || event.keyCode === 27) {
                toggle();
            }
        };
        if (open) {
            window.addEventListener("keydown", handleKeyDown);
        }
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [open, toggle]);
    return (React.createElement(Dialog, { open: open, toggle: toggle, id: "nextdoor_confirmation-id" },
        React.createElement(DialogTarget, null,
            React.createElement(ConfirmationDialog, { scrimSx: { zIndex: 10 } },
                React.createElement(DialogHeader, { sx: { h5: { color: "#0F3E6F", fontSize: "2rem", fontWeight: 700, lineHeight: "3rem" } }, title: "Post your listing on Nextdoor" }),
                React.createElement(Text, { fontSize: "1.5rem", lineHeight: "2rem", mb: "2rem" }, "You will be redirected to Nextdoor to post a new listing. Sign in to your Nextdoor account or create a new account. Once signed in, your listing will be posted automatically."),
                React.createElement(Button, { variant: "primary", height: "4.375rem", width: "100%", onClick: () => {
                        setLoadingSpinner(true);
                        window.location.href = nextdoorShareUrlWithCallback;
                    } }, loadingSpinner ? React.createElement(LoadingSpinnerCircle, null) : "Sign in with nextdoor")))));
};
export default PublishToNextdoorConfirmModal;
