import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useToast } from "@rent_avail/toast";
import { ListingStatus, usePublishNextdoorListingMutation } from "gql/generated/graphql";
import { trackingAssist } from "utils";
import { useListings } from "../context";
import PublishToNextdoorConfirmModal from "./PublishToNextDoorConfirmModal";
import PublishedToNextDoorSuccessModal from "./PublishedToNextDoorSuccessModal";
import PublishToNextdoorButton from "./PublishToNextdoorButton";
import PublishedToNextDoorPendingModal from "./PublishedToNextDoorPendingModal";
import ReAuthenticateWithNextdoorModal from "./ReAuthenticateWithNextdoorModal";
export const PublishToNextdoor = () => {
    const { currentListing, currentUnit: { id: unitId, nextdoor_auth: nextdoorAuth, nextdoor_link: nextdoorLink, nextdoor_share_status: nextdoorShareStatus, public_images: images, }, } = useListings();
    const history = useHistory();
    const { pathname, search } = useLocation();
    const [openConfirmationModel, setOpenConfirmationModal] = useState(false);
    const [openSuccessModel, setOpenSuccessModal] = useState(false);
    const [openPendingModel, setOpenPendingModal] = useState(false);
    const [openReAuthModal, setOpenReAuthModal] = useState(false);
    const [publishNextdoorListingMutation, { loading }] = usePublishNextdoorListingMutation();
    const toast = useToast();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const nextdoorLinkParam = searchParams.get("nextdoor_link");
    const reauthParam = searchParams.get("reauth");
    const listingIsActive = (currentListing === null || currentListing === void 0 ? void 0 : currentListing.listingStatus) === ListingStatus.Active;
    useEffect(() => {
        if (reauthParam === "true") {
            searchParams.delete("reauth");
            toast.success("You have successfully re-authenticated with Nextdoor.", { duration: 5000 });
        }
        else if (nextdoorLinkParam === "") {
            setOpenPendingModal(true);
        }
        else if (nextdoorLinkParam) {
            setOpenSuccessModal(true);
        }
    }, [nextdoorLinkParam, nextdoorShareStatus, reauthParam, searchParams, toast]);
    const trackInitiatePublishEvent = () => {
        trackingAssist.trackEvent("Nextdoor Listing Syndication Initiated", {
            "Unit ID": unitId,
        });
    };
    const publishNextdoorListing = async () => {
        const { data, errors } = await publishNextdoorListingMutation({
            variables: { unitId },
        });
        if (errors || data.publish_nextdoor_listing.__typename === "Error") {
            toast.error("Something went wrong. Please try again.");
        }
        else if (data.publish_nextdoor_listing.unit.nextdoor_share_status === "pending") {
            setOpenPendingModal(true);
        }
        else {
            setOpenSuccessModal(true);
        }
    };
    const onPublish = () => {
        if (!(images === null || images === void 0 ? void 0 : images.length)) {
            toast.error("Please upload an image before publishing to Nextdoor.");
        }
        else if (nextdoorAuth === "active") {
            publishNextdoorListing();
        }
        else {
            setOpenConfirmationModal(true);
        }
        trackInitiatePublishEvent();
    };
    const onButtonClick = () => {
        if (nextdoorAuth === "inactive") {
            setOpenReAuthModal(true);
        }
        else if (nextdoorLink) {
            window.open(nextdoorLink);
        }
        else {
            onPublish();
        }
    };
    const onSuccessModalClose = () => {
        searchParams.delete("nextdoor_link");
        history.push({
            pathname,
            search: searchParams.toString(),
        });
        setOpenSuccessModal(false);
    };
    const onPendingModalClose = () => {
        searchParams.delete("nextdoor_link");
        history.push({
            pathname,
            search: searchParams.toString(),
        });
        setOpenPendingModal(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(PublishToNextdoorButton, { listingIsActive: listingIsActive, nextdoorAuth: nextdoorAuth, nextdoorShareStatus: nextdoorShareStatus, loading: loading, onButtonClick: onButtonClick }),
        React.createElement(PublishToNextdoorConfirmModal, { open: openConfirmationModel, toggle: () => setOpenConfirmationModal(!openConfirmationModel), unitId: unitId }),
        React.createElement(PublishedToNextDoorSuccessModal, { show: openSuccessModel, close: onSuccessModalClose, nextdoorLink: nextdoorLink }),
        React.createElement(PublishedToNextDoorPendingModal, { show: openPendingModel, close: onPendingModalClose }),
        React.createElement(ReAuthenticateWithNextdoorModal, { open: openReAuthModal, toggle: () => setOpenReAuthModal(!openReAuthModal), unitId: unitId })));
};
