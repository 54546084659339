import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDisplayPromotedListing } from "components/hooks/useDisplayPromotedListing";
import { PurchaseSuccessDialog } from "./purchase/PurchaseSuccessDialog";
import { PROMOTION_PARTNER } from "./purchase/constants";
import PromoteListingLearnMoreBanner from "./PromoteListingLearnMoreBanner";
import { PromoteListingBanner } from "./PromoteListingBanner";
import { PromotedListingCard } from "./PromotedListingCard";
export const PromoteListing = () => {
    const [showPurchaseSuccessDialog, setShowPurchaseSuccessDialog] = useState(false);
    const [expiresOn, setExpiresOn] = useState(null);
    const { id: unitId } = useParams();
    const { isAlreadyPromotedForRealtor, shouldDisplayPromotedListing, loading } = useDisplayPromotedListing(unitId);
    const handlePurchase = ({ expiresOn: promotionEndDate }) => {
        setExpiresOn(promotionEndDate);
        setShowPurchaseSuccessDialog(true);
    };
    if (loading) {
        return null;
    }
    const showPromoteListingCta = shouldDisplayPromotedListing;
    return showPromoteListingCta ? (React.createElement(React.Fragment, null,
        React.createElement(PromoteListingBanner, { onPurchase: handlePurchase }),
        React.createElement(PromotedListingCard, null),
        React.createElement(PurchaseSuccessDialog, { isOpen: showPurchaseSuccessDialog, partner: PROMOTION_PARTNER, expiresOn: expiresOn, onClose: () => setShowPurchaseSuccessDialog(false) }))) : (React.createElement(React.Fragment, null,
        !isAlreadyPromotedForRealtor && React.createElement(PromoteListingLearnMoreBanner, null),
        React.createElement(PromotedListingCard, null)));
};
