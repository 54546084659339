import React from "react";
import { Form } from "react-final-form";
import { Admin, AdminAction } from "ui_components";
import { Box } from "@rent_avail/core";
import { Heading } from "@rent_avail/typography";
import { useAdminPromoteListingMutation, useGetMeQuery, useGetUnitForCurrentNavigationQuery, ListingStatus, } from "gql/generated/graphql";
import { useToast } from "@rent_avail/toast";
import { runCallbacksAfterDelay } from "components/shared/utils";
import { isSubscribed, PromotedListingPartners } from "./components/common";
import PromotedListingPartnerCheckbox from "./components/PromotedListingPartnerCheckbox";
import PromotedListingPartnerPurchaseButton from "./components/PromotedListingPurchaseButton";
import useCurrentListing from "../context/useCurrentListing";
import PriceField from "./components/PriceField";
const PromotedListingAdminAction = () => {
    const { data: { me: currentUser } = {} } = useGetMeQuery();
    const { currentListing, currentUnit, refetchCurrentListing } = useCurrentListing();
    const { refetch: refetchUnit } = useGetUnitForCurrentNavigationQuery({
        variables: { id: currentUnit === null || currentUnit === void 0 ? void 0 : currentUnit.id },
    });
    const [purchasePremiumSyndication, { loading }] = useAdminPromoteListingMutation();
    const toast = useToast();
    const userHasValidCard = !!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.valid_card);
    const listingIsActive = (currentListing === null || currentListing === void 0 ? void 0 : currentListing.listingStatus) === ListingStatus.Active;
    const handleError = (data, errors) => {
        if (errors) {
            toast.error("Failed to purchase promoted listing");
        }
        else if (data.admin_promote_listing.__typename === "Error") {
            toast.error((data === null || data === void 0 ? void 0 : data.admin_promote_listing.errors.base[0]) || "Failed to purchase promoted listing");
        }
    };
    const handleSuccess = (premium_syndications) => {
        toast.success(`Promoted listing purchased successfully: ${premium_syndications
            .map(({ partner_name }) => partner_name)
            .join(", ")}`);
        runCallbacksAfterDelay({ callbacks: [refetchUnit, refetchCurrentListing], delay: 3000 });
    };
    const submitForm = async ({ price, rentdot_premier: rentdotPremier, zumper_premier: zumperPremier, realtor_premier: realtorPremier, }) => {
        const { data, errors } = await purchasePremiumSyndication({
            variables: {
                unitId: currentUnit.id,
                priceCents: price * 100,
                partners: [
                    rentdotPremier && PromotedListingPartners.RENTDOT_PREMIER,
                    zumperPremier && PromotedListingPartners.ZUMPER_PREMIER,
                    realtorPremier && PromotedListingPartners.REALTOR_PREMIER,
                ].filter(Boolean),
            },
        });
        if (errors || data.admin_promote_listing.__typename === "Error") {
            handleError(data, errors);
        }
        else {
            handleSuccess(data.admin_promote_listing.unit.active_premium_syndications);
        }
    };
    if (!currentUnit || !currentUser) {
        return null;
    }
    return (React.createElement(Box, { sx: { mt: "2rem" } },
        React.createElement(AdminAction, { className: "inline" },
            React.createElement(Admin, { opened: true, info: {
                    "User has valid credit card": userHasValidCard,
                    "Listing is active": listingIsActive,
                } }),
            userHasValidCard && listingIsActive && (React.createElement(Form, { onSubmit: submitForm, initialValues: {
                    rentdot_premier: isSubscribed(PromotedListingPartners.RENTDOT_PREMIER, currentUnit),
                    zumper_premier: isSubscribed(PromotedListingPartners.ZUMPER_PREMIER, currentUnit),
                    realtor_premier: isSubscribed(PromotedListingPartners.REALTOR_PREMIER, currentUnit),
                }, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Heading, { as: "h5", my: 1 }, "Purchase a promoted listing"),
                    React.createElement(PriceField, null),
                    React.createElement(PromotedListingPartnerCheckbox, { name: "rentdot_premier", disabled: isSubscribed(PromotedListingPartners.RENTDOT_PREMIER, currentUnit) }, "Rent.com"),
                    React.createElement(PromotedListingPartnerCheckbox, { name: "zumper_premier", disabled: isSubscribed(PromotedListingPartners.ZUMPER_PREMIER, currentUnit) }, "Zumper"),
                    React.createElement(PromotedListingPartnerCheckbox, { name: "realtor_premier", disabled: isSubscribed(PromotedListingPartners.REALTOR_PREMIER, currentUnit) }, "RDC"),
                    React.createElement(PromotedListingPartnerPurchaseButton, { loading: loading }))) })))));
};
export default PromotedListingAdminAction;
