/* eslint-disable */
const result = {
    "possibleTypes": {
        "AcceptLeaseInvitationResult": [
            "AcceptLeaseInvitationSuccess",
            "Error"
        ],
        "AddCommentToMaintenanceTicketResult": [
            "AddCommentToMaintenanceTicketSuccess",
            "Error"
        ],
        "AddMaintenanceTicketResult": [
            "AddMaintenanceTicketSuccess",
            "Error"
        ],
        "AddPartnerClickResult": [
            "AddPartnerClickSuccess",
            "Error"
        ],
        "AdminDeleteLeaseResult": [
            "AdminDeleteLeaseSuccess",
            "Error"
        ],
        "Assetable": [
            "Building",
            "Unit"
        ],
        "AssignUnitsToBusinessEntityResult": [
            "AssignUnitsToBusinessEntitySuccess",
            "Error"
        ],
        "BaseError": [
            "InvalidAttributesError"
        ],
        "CancelSignatureRequestResult": [
            "CancelSignatureRequestSuccess",
            "Error"
        ],
        "ChangeLeaseEndDateResult": [
            "ChangeLeaseEndDateSuccess",
            "Error"
        ],
        "CreateBuildingResult": [
            "CreateBuildingSuccess",
            "Error"
        ],
        "CreateCompanyResult": [
            "CreateCompanySuccess",
            "Error"
        ],
        "CreateCreditCardResult": [
            "CreateCreditCardSuccess",
            "Error"
        ],
        "CreateEmbeddedUnclaimedDraftResult": [
            "CreateEmbeddedUnclaimedDraftSuccess",
            "Error"
        ],
        "CreateExternalLeaseResult": [
            "CreateExternalLeaseSuccess",
            "Error"
        ],
        "CreateFraudFeedbackResult": [
            "CreateFraudFeedbackSuccess",
            "Error"
        ],
        "CreateGeneralLedgerAttachmentResult": [
            "CreateGeneralLedgerAttachmentSuccess",
            "Error"
        ],
        "CreateGeneralLedgerResult": [
            "CreateGeneralLedgerSuccess",
            "Error"
        ],
        "CreateIntegrationResult": [
            "CreateIntegrationSuccess",
            "Error"
        ],
        "CreateLandlordInvitationResult": [
            "CreateLandlordInvitationSuccess",
            "Error"
        ],
        "CreateLeaseCommentResult": [
            "CreateLeaseCommentSuccess",
            "Error"
        ],
        "CreateLeaseDocumentResult": [
            "CreateLeaseDocumentSuccess",
            "Error"
        ],
        "CreateLeaseResult": [
            "CreateLeaseSuccess",
            "Error"
        ],
        "CreateListingResult": [
            "CreateListingSuccess",
            "InvalidAttributesError"
        ],
        "CreateMoverMatchRequestResult": [
            "CreateMoverMatchRequestSuccess",
            "Error"
        ],
        "CreateReferralResult": [
            "CreateReferralSuccess",
            "Error"
        ],
        "CreateReferralsResult": [
            "CreateReferralsResultSuccess",
            "Error"
        ],
        "CreateScreeningReportConfigurationResult": [
            "CreateScreeningReportConfigurationSuccess",
            "Error"
        ],
        "CreateUnitResult": [
            "CreateUnitSuccess",
            "Error"
        ],
        "DeleteCompanyForUserResult": [
            "DeleteCompanyForUserSuccess",
            "Error"
        ],
        "DeleteCreditCardResult": [
            "DeleteCreditCardSuccess",
            "Error"
        ],
        "DeleteGeneralLedgerAttachmentResult": [
            "DeleteGeneralLedgerAttachmentSuccess",
            "Error"
        ],
        "DeleteGeneralLedgerResult": [
            "DeleteGeneralLedgerSuccess",
            "Error"
        ],
        "DeleteGeneralLedgersResult": [
            "DeleteGeneralLedgersSuccess",
            "Error"
        ],
        "DeleteImageFromMaintenanceTicketResult": [
            "DeleteImageFromMaintenanceTicketSuccess",
            "Error"
        ],
        "DeleteLandlordInvitationResult": [
            "DeleteLandlordInvitationSuccess",
            "Error"
        ],
        "DeleteLeaseResourceResult": [
            "DeleteLeaseResourceSuccess",
            "Error"
        ],
        "DeleteTenantFromLeaseResult": [
            "DeleteTenantFromLeaseSuccess",
            "Error"
        ],
        "DeleteUnitPublicImageResult": [
            "DeleteUnitPublicImageSuccess",
            "Error"
        ],
        "DestroyBankAccountResult": [
            "DestroyBankAccountSuccess",
            "Error"
        ],
        "DestroyIntegrationResult": [
            "DestroyIntegrationSuccess",
            "Error"
        ],
        "DestroyMessageResult": [
            "DestroyMessageSuccess",
            "Error"
        ],
        "EditGeneralLedgerResult": [
            "EditGeneralLedgerSuccess",
            "Error"
        ],
        "LandlordDeleteLeaseResult": [
            "Error",
            "LandlordDeleteLeaseSuccess"
        ],
        "LandlordPromoteListingResult": [
            "Error",
            "LandlordPromoteListingSuccess"
        ],
        "MeResult": [
            "Landlord",
            "User"
        ],
        "PromoteListingResult": [
            "Error",
            "PromoteListingSuccess"
        ],
        "PublishNextdoorListingResult": [
            "Error",
            "PublishNextdoorListingSuccess"
        ],
        "RenameLeaseResourceResult": [
            "Error",
            "RenameLeaseResourceSuccess"
        ],
        "RenewLeaseResult": [
            "Error",
            "RenewLeaseSuccess"
        ],
        "RequestAuth0PasswordChangeResult": [
            "Error",
            "RequestAuth0PasswordChangeSuccess"
        ],
        "RequestEmailVerificationLinkResult": [
            "Error",
            "RequestEmailVerificationLinkSuccess"
        ],
        "RequestVerifyPhoneResult": [
            "Error",
            "RequestVerifyPhoneSuccess"
        ],
        "ResetShareableApplicationResult": [
            "Error",
            "ResetShareableApplicationSuccess"
        ],
        "ResetVerificationAttemptsResult": [
            "Error",
            "ResetVerificationAttemptsSuccess"
        ],
        "SendEsignLeaseInvitationsResult": [
            "Error",
            "SendEsignLeaseInvitationsSuccess"
        ],
        "SendLeaseResult": [
            "Error",
            "SendLeaseSuccess"
        ],
        "StartGeneralLedgerImportResult": [
            "Error",
            "StartGeneralLedgerImportSuccess"
        ],
        "TenantSignInternalLeaseResult": [
            "Error",
            "TenantSignInternalLeaseSuccess"
        ],
        "ToggleLeaseInvitationArchivedResult": [
            "Error",
            "ToggleLeaseInvitationArchivedSuccess"
        ],
        "ToggleLeaseMonthToMonthResult": [
            "Error",
            "ToggleLeaseMonthToMonthSuccess"
        ],
        "UpdateApplicantInvitationResult": [
            "Error",
            "UpdateApplicantInvitationSuccess"
        ],
        "UpdateApplicantInvoicePaidResult": [
            "Error",
            "UpdateApplicantInvoicePaidSuccess"
        ],
        "UpdateApplicantResult": [
            "Error",
            "UpdateApplicantSuccess"
        ],
        "UpdateApplicationResult": [
            "Error",
            "UpdateApplicationSuccess"
        ],
        "UpdateApplicationUnitIdResult": [
            "Error",
            "UpdateApplicationUnitIdSuccess"
        ],
        "UpdateBankAccountResult": [
            "Error",
            "UpdateBankAccountSuccess"
        ],
        "UpdateBuildingAddressResult": [
            "Error",
            "UpdateBuildingAddressSuccess"
        ],
        "UpdateBuildingResult": [
            "Error",
            "UpdateBuildingSuccess"
        ],
        "UpdateCompanyResult": [
            "Error",
            "UpdateCompanySuccess"
        ],
        "UpdateEmailResult": [
            "Error",
            "UpdateEmailSuccess"
        ],
        "UpdateEsignRequestResult": [
            "Error",
            "UpdateEsignRequestSuccess"
        ],
        "UpdateIntegrationResult": [
            "Error",
            "UpdateIntegrationSuccess"
        ],
        "UpdateLandlordInvitationResult": [
            "Error",
            "UpdateLandlordInvitationSuccess"
        ],
        "UpdateLeaseInternalToExternalResult": [
            "Error",
            "UpdateLeaseInternalToExternalSuccess"
        ],
        "UpdateLeaseResult": [
            "Error",
            "UpdateLeaseSuccess"
        ],
        "UpdateLeaseUnitIdResult": [
            "Error",
            "UpdateLeaseUnitIdSuccess"
        ],
        "UpdateListingResult": [
            "InvalidAttributesError",
            "UpdateListingSuccess"
        ],
        "UpdateMaintenanceTicketResult": [
            "Error",
            "UpdateMaintenanceTicketSuccess"
        ],
        "UpdateMeResult": [
            "Error",
            "UpdateMeSuccess"
        ],
        "UpdateMessageResult": [
            "Error",
            "UpdateMessageSuccess"
        ],
        "UpdateMyPortfolioSizeResult": [
            "Error",
            "UpdateMyPortfolioSizeSuccess"
        ],
        "UpdatePastPaymentReportingResult": [
            "Error",
            "UpdatePastPaymentReportingSuccess"
        ],
        "UpdatePaymentChargeIdResult": [
            "Error",
            "UpdatePaymentChargeIdSuccess"
        ],
        "UpdateRentalHistoryCreditReportingResult": [
            "Error",
            "UpdateRentalHistoryCreditReportingSuccess"
        ],
        "UpdateScreeningReportConfigurationResult": [
            "Error",
            "UpdateScreeningReportConfigurationSuccess"
        ],
        "UpdateSignatureResult": [
            "Error",
            "UpdateSignatureSuccess"
        ],
        "UpdateTenantSignatureResult": [
            "Error",
            "UpdateTenantSignatureSuccess"
        ],
        "UpdateUnitPhotoOrderResult": [
            "Error",
            "UpdateUnitPhotoOrderSuccess"
        ],
        "UploadLeaseResourceResult": [
            "Error",
            "UploadLeaseResourceSuccess"
        ],
        "UploadSignedLeaseResult": [
            "Error",
            "UploadSignedLeaseSuccess"
        ],
        "UpsertAllstateInsuranceInquiryResult": [
            "Error",
            "UpsertAllstateInsuranceInquirySuccess"
        ],
        "UpsertBusinessEntityResult": [
            "Error",
            "UpsertBusinessEntitySuccess"
        ],
        "UpsertSureInsuranceQueryResult": [
            "Error",
            "UpsertSureInsuranceQuerySuccess"
        ],
        "UpsertUserLegalInfoResult": [
            "Error",
            "UpsertUserLegalInfoSuccess"
        ],
        "VerifyEmailResult": [
            "Error",
            "VerifyEmailSuccess"
        ],
        "VerifyPhoneResult": [
            "Error",
            "VerifyPhoneSuccess"
        ]
    }
};
export default result;
