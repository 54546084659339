import React from "react"
import { FadeCollapseList } from "ui_components"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import Lead from "./Lead"
import { useSettings } from "../../../../settings"

const propTypes = {
  LEADS: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
  UNIT: PropTypes.shape({
    id: PropTypes.number.isRequired,
    listingStepperStarted: PropTypes.bool.isRequired,
    listingComplete: PropTypes.shape({
      summary: PropTypes.bool.isRequired,
    }).isRequired,
    isListingComplete: PropTypes.bool.isRequired,
    list_start_date: PropTypes.instanceOf(Date),
    publicly_listed: PropTypes.bool.isRequired,
  }).isRequired,
}

const defaultProps = {}

const Leads = ({ LEADS, UNIT, ...props }) => {
  const { listingStepperStarted, listingComplete } = UNIT
  const listingPath = !listingStepperStarted || !listingComplete.summary ? "new" : "edit"
  const listingUrl = `/app/landlords/units/${UNIT.id}/listings/${listingPath}`
  const leadMessage = `${
    listingStepperStarted ? "Finish" : "Build"
  } your listing to start receiving leads.`
  const { promotedListingTooltip } = useSettings()
  const promotedListingTooltipEnabled = promotedListingTooltip === "enabled"

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FadeCollapseList {...props} role="list" aria-label="leads">
      {LEADS.length ? (
        LEADS.sortBy("createdAt", true).map((LEAD) => (
          <Lead
            key={LEAD.id}
            LEAD={LEAD}
            UNIT={UNIT}
            showPromotedListingTooltip={promotedListingTooltipEnabled}
          />
        ))
      ) : (
        <div className="padding-2-t">
          <div>
            You have <strong>0</strong> lead messages
          </div>
          {!UNIT.list_start_date ? (
            <div className="margin-1-t">
              <Link className="inline" to={listingUrl}>
                {leadMessage}
              </Link>
            </div>
          ) : null}
        </div>
      )}
    </FadeCollapseList>
  )
}

Leads.propTypes = propTypes

Leads.defaultProps = defaultProps

export default Leads
