import ReactiveRecord, { Model } from "reactiverecord"
import { where, objToQueryString } from "utils"

class Lease extends Model {
  static MESSAGES = {
    LEASE_CREATED_FROM_APPLICATION:
      "Great. We've added your applicants to a new lease! Specify more information about your lease using the form below.",
  }

  static schema = {
    lease: Object,
    all_tenants_signed: Boolean,
    asbestos_issues: Boolean,
    auto_late_fee: Boolean,
    bedbugs_eradicated: Boolean,
    expired: Boolean,
    in_renewal_period: Boolean,
    is_finalized: Boolean,
    esignable: Boolean,
    internal: Boolean,
    external: Boolean,
    landlord_signature_requested: Boolean,
    lead_issues: Boolean,
    mold_issues: Boolean,
    month_to_month: Boolean,
    pet_deposit_refundable: Boolean,
    prorated: Boolean,
    radon_issues: Boolean,
    renewed: Boolean,
    rent_charges_generated: Boolean,
    smoking_prohibited: Boolean,
    stepper_complete: Boolean,
    tenants_signed: Boolean,
    uploaded: Boolean,
    category: String,
    require_online_payments: Boolean,
    require_renters_insurance: Boolean,
    confirmed_first_months_rent: Boolean,
    confirmed_move_in: Boolean,
    confirmed_renters_insurance: Boolean,
    confirmed_utility_transfer: Boolean,
    confirmed_inspection_checklist: Boolean,
    tenant_paid_first_months_rent: Boolean,
    tenant_scheduled_move_in: Boolean,
    tenant_provided_proof_of_insurance: Boolean,
    tenant_transferred_utilities: Boolean,
    tenant_completed_inspection_list: Boolean,
    demolish_issues: Boolean,
    ordinance_location_issues: Boolean,
    lead_paint_disclosure_required: Boolean,
    key_exchange_message: String,
    key_exchange_location: String,
    key_exchange_date: String,
    utility_transfer_gas: String,
    utility_transfer_water: String,
    utility_transfer_electric: String,
    utility_transfer_garbage: String,
    utility_transfer_internet: String,
    utility_transfer_cable: String,
    only_latest: Boolean,
    tenant_names: String,
    tenant_first_names: String,
    stop_payments: Boolean,
    notify_on_pmt_init: Boolean,
    notify_on_pmt_deposit: Boolean,
    notify_on_pmt_change: Boolean,
    notify_on_pmt_reminder: Boolean,
    remind_on_days_before: Number,
    remind_on_day_before: Boolean,
    remind_on_day: Boolean,
    remind_on_days_late: Number,
    future: Boolean,
    current: Boolean,
    next_lease_charge: Object,
    credit_reporting: Boolean,
    hide_late_report_notifications: Boolean,

    animal_rules: String,
    asbestos_explanation: String,
    attachment_content_type: String,
    attachment_file_name: String,
    bedbugs_explanation: String,
    bedbugs_floors: String,
    bedbugs_found: String,
    conditions_affecting_habitability: String,
    date_rent_due: {
      type: String,
      default: "first",
    },
    dog_charge: String,
    end_date: String,
    late_fee: String,
    lead_explanation: String,
    lead_documents: String,
    lessor_name: String,
    mold_explanation: String,
    move_in: String,
    move_in_fee: String,
    move_out_fee: String,
    move_in_suggested: String,
    notice_of_foreclosure: String,
    parking: String,
    payment_pattern: String,
    pet_deposit: String,
    prorated_rent: String,
    radon_explanation: String,
    rent: {
      type: String,
      default: "1500",
    },
    riders: String,
    security_deposit_bank_address1: String,
    security_deposit_bank_city: String,
    security_deposit_bank_name: String,
    security_deposit_bank_state: String,
    security_deposit_bank_zip: String,
    security_deposit_data: String,
    start_date: String,
    state: String,
    demolish_explanation: String,
    ordinance_location_explanation: String,

    auto_late_fee_days_after: Number,
    attachment_file_size: Number,
    sent_at: Date,
    clause_order: Array,
    rule_order: Array,
    tenants_for_lease: Array,
    has_attachment: Boolean,
    purchased_lemonade_user_ids: Array,
    failed_lemonade_quote_user_ids: Array,
    failed_lemonade_purchase_user_ids: Array,
    legal_terms_accepted_at: Date,
    legal_terms_acceptance_required: Boolean,

    // Associations
    application_id: Number,
    lease_template: Object,
    signatures: Array,
    landlord_signatures: Array,
    unit: Object,
    unit_id: Number,
    unit_attributes: Object,
    charges: Array,
    charges_attributes: Array,
    charge_series_attributes: Array,
    esign_requests: Array,
    lemonade_insurance_inquiries: Array,
    sure_insurance_queries: Array,
    lease_invitations: Array,
    lease_invitations_attributes: Array,
    lease_rules: Array,
    lease_rules_attributes: Array,
    lease_clauses: Array,
    lease_clauses_attributes: Array,
    lease_resources: Array,
    lease_resources_attributes: Array,
    landlord: Object,
    landlord_id: Number,
    landlord_attributes: Array,
    current_charges_balance_cents: Number,
    overdue_charges_balance_cents: Number,
    landlord_charge_balance_display: String,
    remove_attachment: Boolean,
    renters_insurance_complete: Boolean,
    available_renters_insurance: String,
    _timestamps: true,
  }

  get canUnlock() {
    return this.sent_at && !this.is_finalized && this.internal
  }

  static dateRentDueOptions = [
    "first",
    "2nd",
    "3rd",
    "4th",
    "5th",
    "6th",
    "7th",
    "8th",
    "9th",
    "10th",
    "11th",
    "12th",
    "13th",
    "14th",
    "15th",
    "16th",
    "17th",
    "18th",
    "19th",
    "20th",
    "21st",
    "22nd",
    "23rd",
    "24th",
    "25th",
    "26th",
    "27th",
    "last",
  ]

  buildCharges(overrides = {}) {
    const {
      start_date,
      end_date,
      date_rent_due,
      rent: amount,
      dog_charge,
      pet_deposit,
      security_deposit_data,
      parking,
      move_in_fee,
      move_out_fee,
      prorated_rent,
      prorated,
      animal_rules,
    } = this

    const { building: { address1 } = {} } = this.unit || {}

    const charges_attributes = []

    const dummyCharge = { notify_tenants: false, paid_directly: false }

    const dummyRentCharge = {
      ...dummyCharge,
      amount,
      for: "rent",
    }

    const defaultForWording = address1 ? ` for ${address1}` : ""

    const firstPaymentDueDate = Date.create(start_date)

    const lastPaymentDueDate = Date.create(end_date)

    const {
      securityDepositDataDueDate = start_date,
      securityDepositPaidDirectly = false,
      petDepositDueDate = start_date,
      petDepositPaidDirectly = false,
      moveInFeeDueDate = start_date,
      moveInFeePaidDirectly = false,
      moveOutFeeDueDate = end_date,
      moveOutFeePaidDirectly = false,
    } = overrides

    // Add the security deposit if necessary
    if (parseFloat(security_deposit_data)) {
      charges_attributes.push({
        ...dummyCharge,
        for: "security_deposit",
        amount: security_deposit_data,
        due_date: securityDepositDataDueDate,
        paid_directly: securityDepositPaidDirectly,
        description: `Security deposit${defaultForWording}`,
      })
    }

    // Add the pet deposit if necessary
    if (parseFloat(pet_deposit)) {
      const chargeFor = animal_rules === "fee" ? "fee" : "security_deposit"
      charges_attributes.push({
        ...dummyCharge,
        for: chargeFor,
        amount: pet_deposit,
        due_date: petDepositDueDate,
        paid_directly: petDepositPaidDirectly,
        description: `Pet ${animal_rules === "fee" ? "fee" : "deposit"}${defaultForWording}`,
      })
    }

    // Add the move-in fee if necessary
    if (parseFloat(move_in_fee)) {
      charges_attributes.push({
        ...dummyCharge,
        for: "fee",
        amount: move_in_fee,
        due_date: moveInFeeDueDate,
        paid_directly: moveInFeePaidDirectly,
        description: `Move-in fee${defaultForWording}`,
      })
    }

    if (date_rent_due === "last") {
      firstPaymentDueDate.endOfMonth()
      lastPaymentDueDate.endOfMonth()
    } else {
      firstPaymentDueDate.set({
        date: date_rent_due === "first" ? 1 : parseInt(date_rent_due),
      })
      lastPaymentDueDate.set({
        date: date_rent_due === "first" ? 1 : parseInt(date_rent_due),
      })
    }
    const originalFirstPaymentDueDate = firstPaymentDueDate.format("{year}-{MM}-{dd}")
    while (
      firstPaymentDueDate.format("{year}-{MM}-{dd}") <=
      lastPaymentDueDate.format("{year}-{MM}-{dd}")
    ) {
      const newCharge = {
        ...dummyRentCharge,
        due_date: firstPaymentDueDate.format("{year}-{MM}-{dd}"),
        description: `${firstPaymentDueDate.format("{Month}")} rent`,
      }
      // Add the prorated amount to the first charge
      if (parseFloat(prorated_rent) && prorated) {
        if (newCharge.due_date === originalFirstPaymentDueDate) {
          newCharge.amount = parseFloat(prorated_rent)
          newCharge.description = newCharge.description.replace("rent", "prorated rent")
        }
      }
      // Add the parking fee if necessary
      if (parseFloat(parking)) {
        newCharge.amount = parseFloat(newCharge.amount) + parseFloat(parking)
        newCharge.description += `${parseFloat(dog_charge) ? "," : " and"} parking fee`
      }
      // Add the pet fee if necessary
      if (parseFloat(dog_charge)) {
        newCharge.amount = parseFloat(newCharge.amount) + parseFloat(dog_charge)
        newCharge.description += `${parseFloat(parking) ? "," : ""} and pet rent`
      }

      newCharge.description += defaultForWording
      charges_attributes.push(newCharge)

      firstPaymentDueDate.addMonths(1)
      if (date_rent_due === "last") {
        firstPaymentDueDate.endOfMonth()
      }
    }
    // Add the move-out fee if necessary
    if (parseFloat(move_out_fee)) {
      charges_attributes.push({
        ...dummyCharge,
        for: "fee",
        amount: move_out_fee,
        due_date: moveOutFeeDueDate,
        paid_directly: moveOutFeePaidDirectly,
        description: `Move-out fee${defaultForWording}`,
      })
    }
    return charges_attributes
  }

  get numberedClauses() {
    return this.lease_clauses.map((leaseClause, index) => ({
      ...leaseClause,
      name: `${index + 1}. ${leaseClause.name}`,
    }))
  }

  get pastDueChargesBalance() {
    return !this.overdue_charges_balance_cents
      ? "0.00"
      : (this.overdue_charges_balance_cents / 100).toMoney()
  }

  get indexPath() {
    return `/app/landlords/units/${this.unit_id}/leases`
  }

  get showPath() {
    return `/app/landlords/units/${this.unit_id}/leases/${this.id}`
  }

  get tenantShowPath() {
    return `/app/tenants/leases/${this.id}`
  }

  get editPath() {
    return this.showPath
  }

  get tenantDownloadPath() {
    return `/new_ui/tenants/units/${this.unit_id}/leases/${this.id}/download`
  }

  get landlordDownloadPath() {
    return `/api/v2/landlords/leases/${this.id}?print=true`
  }

  get paymentsPath() {
    if (this.rent_charges_generated && this.tenants_for_lease.length) {
      return `/app/landlords/units/${this.unit_id}/charges`
    }
    if (!this.external) {
      return `/app/landlords/units/${this.unit_id}/leases/${this.id}/payments`
    }
    return `/app/landlords/units/${this.unit_id}/charges/collect?lease_id=${this.id}`
  }

  get tenantPaymentsPath() {
    if (this.rent_charges_generated) {
      return "/app/tenants/payments"
    }
    return "/app/tenants/bank-accounts"
  }

  get renewPath() {
    return `/new_ui/units/${this.unit_id}/leases/${this.id}/renew`
  }

  get landlordEditing() {
    return this.tenantSignatures.length && !this.sent_at && this.internal
  }

  get landlordSignature() {
    const [landlordSignature] = this.signatures::where({
      user_id: this.landlord_id,
    })
    return landlordSignature
  }

  get tenantSignatures() {
    const tenantSignatures = this.signatures.filter(
      (signature) => signature.user_id !== this.landlord_id,
    )
    return tenantSignatures
  }

  get showTodos() {
    return Date.create(this.start_date) > Date.create("60 days ago")
  }

  get humanStartDate() {
    return Date.create(this.start_date).medium()
  }

  get humanEndDate() {
    return Date.create(this.end_date).medium()
  }

  get leaseDates() {
    return `${this.humanStartDate} to ${this.humanEndDate}`
  }

  get canRenew() {
    return this.is_finalized && !this.month_to_month && !this.renewed
  }

  get abandonedPaymentStepper() {
    return this.external && (!this.rent_charges_generated || !this.tenants_for_lease.length)
  }

  get landlordDisplayBalance() {
    if (!this.overdue_charges_balance_cents) {
      return "$ 0.00"
    }
    return (this.overdue_charges_balance_cents / -100).toMoney({
      signSpacer: true,
    })
  }

  get overdueCharges() {
    return this.charges.filter((charge) => charge.status === "Overdue")
  }

  get finalizedOrUploaded() {
    return this.is_finalized || this.external
  }

  static bedBugOptions = [
    ["none", "No known bed bug issues"],
    ["building", "Bed bugs found in the building"],
    ["apartment", "Bed bugs found in the apartment"],
    ["other", "Other bed bug issues"],
  ]

  static animalRuleOptions = [
    ["no", "Pets are not allowed"],
    [
      "deposit",
      "Pets are allowed, but there is a deposit and I'll name the individual pets allowed on the agreement.",
    ],
    ["fee", "Pets are allowed, but there is a one-time fee."],
    ["additional_rent", "Pets are allowed, but there is an additional monthly charge per pet."],
    [
      "yes",
      "Pets are allowed, and tenants are liable for any additional maintenance caused by pet issues.",
    ],
  ]
}

export default ReactiveRecord.model("Lease", Lease)
