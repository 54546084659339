import { gql } from "@apollo/client"
import { resolveMutation } from "../../../resolvers/resolver"

const UPDATE_SIGNATURE_MUTATION = gql`
  mutation updateSignature($signatureId: ID, $confirm: Boolean, $name: String) {
    update_signature(input: { attributes: { id: $signatureId, confirm: $confirm, name: $name } })
      @client {
      ... on UpdateSignatureSuccess {
        signature {
          id
          signing_data {
            name
          }
        }
      }
      ... on Error {
        errors
      }
    }
  }
`

export const updateSignatureResolver = {
  update_signature: (...args) => {
    const [
      ,
      {
        input: {
          attributes: { id, ...body },
        },
      },
    ] = args
    return resolveMutation({ args, url: `/landlords/signatures/${id}`, method: "PUT", body })
  },
}

export default UPDATE_SIGNATURE_MUTATION
