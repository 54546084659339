import React, { useMemo } from "react";
import { ArrowRight, ExternalLink, EyeOff, RefreshCcw } from "react-feather";
import { Box, Flex } from "@rent_avail/core";
import LoadingSpinnerCircle from "ui_components/LoadingSpinnerCircle";
const NEXTDOOR_POST_STATE = {
    CONNECTION_LOST: "CONNECTION_LOST",
    OFF_MARKET: "OFF_MARKET",
    PENDING: "PENDING",
    PUBLISHED: "PUBLISHED",
    PUBLISHING: "PUBLISHING",
    READY_TO_PUBLISH: "READY_TO_PUBLISH",
};
const PublishToNextdoorButton = ({ listingIsActive, nextdoorAuth, nextdoorShareStatus, loading, onButtonClick, }) => {
    const buttonState = useMemo(() => {
        if (nextdoorShareStatus && nextdoorAuth === "inactive") {
            return NEXTDOOR_POST_STATE.CONNECTION_LOST;
        }
        if (loading) {
            return NEXTDOOR_POST_STATE.PUBLISHING;
        }
        if (nextdoorShareStatus === "pending") {
            return NEXTDOOR_POST_STATE.PENDING;
        }
        if (nextdoorShareStatus === "published") {
            return NEXTDOOR_POST_STATE.PUBLISHED;
        }
        if (listingIsActive) {
            return NEXTDOOR_POST_STATE.READY_TO_PUBLISH;
        }
        return NEXTDOOR_POST_STATE.OFF_MARKET;
    }, [listingIsActive, nextdoorAuth, nextdoorShareStatus, loading]);
    let buttonColor;
    let buttonIcon;
    let buttonText;
    switch (buttonState) {
        case NEXTDOOR_POST_STATE.CONNECTION_LOST:
            buttonText = "Connection Lost";
            buttonIcon = React.createElement(Box, { as: RefreshCcw, sx: { stroke: listingIsActive ? "red_500" : "#c7c7c7" } });
            buttonColor = listingIsActive ? "red_500" : "#c7c7c7";
            break;
        case NEXTDOOR_POST_STATE.OFF_MARKET:
            buttonText = "Off Market";
            buttonIcon = React.createElement(EyeOff, { className: "light-gray-500" });
            buttonColor = "ui_900";
            break;
        case NEXTDOOR_POST_STATE.PENDING:
            buttonText = "Publishing";
            buttonIcon = React.createElement(EyeOff, { className: "light-gray-500" });
            buttonColor = "ui_900";
            break;
        case NEXTDOOR_POST_STATE.PUBLISHED:
            buttonText = "Published";
            buttonIcon = React.createElement(ExternalLink, { className: "navy-blue-300" });
            buttonColor = "blue_500";
            break;
        case NEXTDOOR_POST_STATE.PUBLISHING:
            buttonText = "Publishing...";
            buttonIcon = (React.createElement(Box, { className: "navy-blue-500", sx: { height: "2rem" } },
                React.createElement(LoadingSpinnerCircle, null)));
            buttonColor = "ui_900";
            break;
        case NEXTDOOR_POST_STATE.READY_TO_PUBLISH:
            buttonText = "Publish";
            buttonIcon = React.createElement(ArrowRight, { className: "navy-blue-500" });
            buttonColor = "blue_500";
            break;
        default:
            break;
    }
    const disabled = (!listingIsActive && buttonState === NEXTDOOR_POST_STATE.CONNECTION_LOST) ||
        [
            NEXTDOOR_POST_STATE.OFF_MARKET,
            NEXTDOOR_POST_STATE.PENDING,
            NEXTDOOR_POST_STATE.PUBLISHING,
        ].includes(buttonState);
    return (React.createElement(Flex, { as: "button", disabled: disabled, "aria-labelledby": "publish-to-nextdoor", sx: {
            alignItems: "center",
            backgroundColor: "ui_100",
            border: "none",
            borderRadius: "12rem",
            cursor: disabled ? "not-allowed" : "pointer",
            padding: "1rem 1.17rem",
            fontSize: "1.5rem",
            fontFamily: "'Nunito', sans-serif",
        }, onClick: onButtonClick },
        React.createElement(Box, { as: "img", src: "/images/nextdoor.png", alt: "Nextdoor", sx: { width: "2rem" } }),
        React.createElement(Box, { as: "span", sx: { fontWeight: 800, marginLeft: "0.833rem", color: "#2d2d2e" } }, "Nextdoor\u00A0"),
        React.createElement(React.Fragment, null,
            React.createElement(Box, { as: "span" }, "|\u00A0"),
            React.createElement(Box, { as: "span", id: "publish-to-nextdoor", sx: {
                    color: buttonColor,
                    mr: "0.83rem",
                } }, buttonText),
            buttonIcon)));
};
export default PublishToNextdoorButton;
