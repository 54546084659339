import React from "react";
import { Box } from "@rent_avail/core";
const PromotedListingValidationError = ({ errorMessage, currentUnitId, }) => {
    const sqftAndImageRelatedErrors = errorMessage.includes("sqft can't be empty") || errorMessage.includes("at least 1 image");
    const parsedError = sqftAndImageRelatedErrors
        ? "Error: Your listing must include sqft and at least one photo before proceeding."
        : errorMessage;
    const editListingURL = `/app/landlords/units/${currentUnitId}/listings/edit`;
    return (React.createElement(Box, { sx: { fontSize: "1.334rem", textAlign: "end" } },
        React.createElement(Box, { sx: { color: "red_500", display: "inline" } }, parsedError),
        !!sqftAndImageRelatedErrors && (React.createElement(Box, { sx: { display: "inline" } },
            " ",
            React.createElement(Box, { as: "a", href: editListingURL, className: "inline", sx: { cursor: "pointer", color: "blue_700", display: "inline" } }, "Edit your listing")))));
};
export default PromotedListingValidationError;
