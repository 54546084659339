import React from "react";
import { Flex } from "@rent_avail/core";
import { Check, TrendingUp, Zap } from "@rent_avail/icons";
import { Text } from "@rent_avail/typography";
import PromotedListingsHowItWorksListItem from "./PromotedListingsHowItWorksListItem";
const listItems = [
    {
        icon: Check,
        title: "Publish Listing",
        content: "Your published listing will be syndicated to over a dozen sites.",
    },
    {
        icon: Zap,
        title: "Promote listing",
        content: (React.createElement(React.Fragment, null,
            "Once published, purchase Promoted Listings from your listing tab.",
            " ",
            React.createElement(Text, { as: "span", fontWeight: 700 }, "Your listing will be displayed among the top results on our promotion partner networks."))),
    },
    {
        icon: TrendingUp,
        title: "Get leads faster",
        content: "Your promoted listing will have greater visibility, making it easier for leads to find your rental.",
    },
];
const PromotedListingsHowItWorksList = () => (React.createElement(Flex, { as: "ul", sx: { flexDirection: "column", listStyle: "none", gap: "3.5rem", pl: 0 } }, listItems.map(({ icon, title, content }) => (React.createElement(PromotedListingsHowItWorksListItem, { icon: icon, title: title, content: content })))));
export default PromotedListingsHowItWorksList;
