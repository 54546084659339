import React, { useMemo, useState } from "react";
import { Box, Flex } from "@rent_avail/core";
import { Button, DulceField, DulceAddress, AlignRow, AlignRow__Item } from "ui_components";
const AddBuilding = ({ submitting, handleBack, handleNext, errorMessage }) => {
    const [building, setBuilding] = useState({});
    const [unitNumber, setUnitNumber] = useState(null);
    const [unitType, setUnitType] = useState("apt");
    const disabled = useMemo(() => !building.address1 || !unitType || (unitType !== "sfh" && !unitNumber), [building, unitNumber, unitType]);
    const handleUnitTypeChange = ({ target }) => {
        if (target === null || target === void 0 ? void 0 : target.value) {
            setUnitType(target.value);
        }
    };
    const unitTypeOptions = [
        React.createElement("option", { key: "apt", value: "apt" }, "Apartment"),
        React.createElement("option", { key: "cndf", value: "cndf" }, "Condominium"),
        React.createElement("option", { key: "sfh", value: "sfh" }, "Single-family Home"),
        React.createElement("option", { key: "town", value: "town" }, "Townhouse"),
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { as: "h4" }, "Add a Building"),
        React.createElement(DulceAddress, { autoFocus: true, onChange: ({ target: { name, value } }) => {
                const attr = name.replace(/(\[|\])/g, "");
                setBuilding({
                    ...building,
                    [attr]: value,
                });
            } }),
        React.createElement(DulceField, { type: "select", selectOptions: unitTypeOptions, labelText: "Select the property type", labelClassName: "addon-building", onChange: handleUnitTypeChange }),
        unitType !== "sfh" && (React.createElement(DulceField, { required: unitType === "apt", labelText: "Enter the Unit Number", onChange: ({ target }) => {
                setUnitNumber(target.value);
            } })),
        errorMessage ? (React.createElement(Flex, { sx: { alignItems: "flex-end", flexDirection: "column" } },
            React.createElement(Box, { sx: { color: "red_500", fontSize: "1.2rem" }, key: errorMessage }, errorMessage))) : null,
        React.createElement(AlignRow, { className: "margin-2-t", grow: true, style: { borderCollapse: "collapse" } },
            React.createElement(AlignRow__Item, { middle: true, className: "padding-0p5-r pull-left" },
                React.createElement(Button, { disabled: submitting, onClick: () => {
                        handleBack();
                    } }, "Back")),
            React.createElement(AlignRow__Item, { middle: true, className: "pull-right" },
                React.createElement(Button, { className: "button--primary", loading: submitting, disabled: disabled || submitting, onClick: () => {
                        handleNext({ building, unitNumber, unitType });
                    } }, submitting ? "Saving" : "Add Building")))));
};
export default AddBuilding;
